import React from "react"
import styled from "styled-components"

import Layout from "../components/layouts/Layout"

import { Slider, ContactSection } from "../components/elements"
import {
  SEO,
  Section,
  AlignCenter,
  Color,
  Media,
  ButtonLink,
  SectionSmall,
} from "../components/utilities"
import { lighten } from "polished"

import pool1 from "../images/home1.jpg"
import pool2 from "../images/donut.jpg"
import slide from "../images/slide.jpg"

const SliderGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;

  h3 {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 24px;
  }

  ${Media.below.desktop`
    grid-template-columns: 1fr;
    gap: 2rem;
  `};
`

const IndexGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 280px 280px;
  grid-gap: 1rem;
  height: 100%;

  ${Media.below.desktop`
    grid-template-rows: 320px 320px;
  `}

  ${Media.below.tablet`
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  `}
`

const GridContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`

const ImageBlock = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;

  ${Media.below.tablet`
    height: 200px;
  `}
`

const IndexBlock = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  background: ${lighten(0.4, Color.primary)};
  color: ${Color.grey};
  padding: 2rem;
  line-height: 150%;
  text-align: center;

  h2 {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 24px;
  }

  ${ButtonLink} {
    margin-top: 1rem;
  }
`

const StyledSection = styled(Section)`
  border-bottom: 1rem solid ${Color.white};
`

const slides = [
  {
    image: slide,
    content: () => (
      <SliderGrid>
        <div>
          <h3>Swimming Pool Maintenance</h3>
          <p>
            We pride ourselves on keeping maintenance to a minimum so you are
            spending more time poolside with family and friends.
          </p>
          <ButtonLink to="/contact-us/" modifiers={["secondary"]}>
            Schedule Your Maintenance Call
          </ButtonLink>
        </div>
        <div>
          <AlignCenter>
            <h3>Why Choose Hunt Pools &amp; Service</h3>
          </AlignCenter>
          <SectionSmall>
            <AlignCenter>
              <p>
                We have a great commitment to excellence, reliability,
                integrity, quality, value and unparalleled customer service. We
                pride ourselves on making your experiences fun and worry free.
                Pools are FUN... We want to keep it that way
              </p>
            </AlignCenter>
            <AlignCenter>
              <ButtonLink to="/about-us/" modifiers={["secondary"]}>
                About Hunt Pools and Service
              </ButtonLink>
            </AlignCenter>
          </SectionSmall>
        </div>
      </SliderGrid>
    ),
  },
]

const IndexGridSection = () => (
  <GridContainer>
    <IndexGrid>
      <ImageBlock
        src={pool1}
        alt="Child smiling while exiting swimming pool using pool ladder"
      />
      <IndexBlock>
        <SectionSmall>
          <h2>Pool Builds</h2>
          <p>
            We build swimming pools that deliver superior design and value. We
            partner with only the highest quality subcontractors who share the
            common quality and pride in their work.
          </p>
          <ButtonLink to="/pool-build/" modifiers={["secondary"]}>
            Build A Pool
          </ButtonLink>
        </SectionSmall>
      </IndexBlock>
      <IndexBlock>
        <div>
          <SectionSmall>
            <h2>Pool Services</h2>
            <p>
              We provide quality services to keep your pool looking and
              functioning at its highest levels.
            </p>
            <ButtonLink to="/pool-service/" modifiers={["secondary"]}>
              Our Pool Service
            </ButtonLink>
          </SectionSmall>
        </div>
      </IndexBlock>
      <ImageBlock
        src={pool2}
        alt="Boy relaxing on a pink donut float in swimming pool"
      />
    </IndexGrid>
  </GridContainer>
)

const IndexPage = () => (
  <Layout>
    <SEO
      title="Pool Design & Construction Ohio"
      description="Custom luxury pool design and construction with reliable and exceptional quality. Pool contractors Perrysburg and Northwest Ohio"
    />
    <StyledSection modifiers={["blue"]}>
      <AlignCenter>
        <SectionSmall>
          <h1>Northwest Ohio's Premium Pool Builder</h1>
          <p>
            Hunt Pools &amp; Service is Northwest Ohio's premier swimming pool
            builder. We build unmatched quality in-ground swimming pools
            designed for fun, exercise, relaxation and quality family time.
            Proudly serving Toledo, Sylvania, Maumee, Perrysburg, Whitehouse,
            Monclova, Holland and surrounding cities.
          </p>
        </SectionSmall>
      </AlignCenter>
    </StyledSection>

    <IndexGridSection />

    <Slider slides={slides} />

    <ContactSection />
  </Layout>
)

export default IndexPage
